/* eslint-disable no-unused-vars */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../Utility';

const initialState = {
    id: null,
    description: null,
    filePath: null,
    error: null,
    loading: false,
};

const exportMemoryStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const exportMemorySuccess = (state, action) => {
    return updateObject( state, {
        id: action.id,
        description: action.description,
        filePath: action.filePath,
        error: null,
        loading: false
    } );
};

const exportMemoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const importMemoryStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const importMemorySuccess = (state, action) => {
    return updateObject( state, {
        id: action.id,
        description: action.description,
        filePath: action.filePath,
        error: null,
        loading: false
    } );
};

const importMemoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EXPORT_MEMORY_START: return exportMemoryStart(state, action);
        case actionTypes.EXPORT_MEMORY_SUCCESS: return exportMemorySuccess(state, action);
        case actionTypes.EXPORT_MEMORY_FAIL: return exportMemoryFail(state, action);
        case actionTypes.IMPORT_MEMORY_START: return importMemoryStart(state, action);
        case actionTypes.IMPORT_MEMORY_SUCCESS: return importMemorySuccess(state, action);
        case actionTypes.IMPORT_MEMORY_FAIL: return importMemoryFail(state, action);
        default:
            return state;
    }
};

export default reducer;