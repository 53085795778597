import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

// TODO: Revert to full list of routes:
/*<NavigationItem link="/" exact>Home</NavigationItem>
    <NavigationItem link="/about" exact>About</NavigationItem>
    <NavigationItem link="/projects" exact>Projects</NavigationItem>
    <NavigationItem link="/resume" exact>Resume</NavigationItem>
    <NavigationItem link="/contact" exact>Contact</NavigationItem>*/

const navigationItems = ( props ) => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact>Home</NavigationItem>
        
        <NavigationItem link="/resume" exact>Resume</NavigationItem>
        {props.isAuthenticated ? <NavigationItem link="/adminpanel">Admin Panel</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem link="/logout">Logout</NavigationItem> : null}
    </ul>
);

export default navigationItems;