/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from '../../components/Wrappers/SectionTitle/SectionTitle';

import Aux from '../../hoc/Auxiliary/Auxiliary';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
//import classes from './Contact.module.css'

class Contact extends Component {

    componentDidMount () {
        console.log(this.props);
    }

    submitHandler = (event) =>{
        event.preventDefault();
        /*if( !formdata.name ){
          setError(true);
          setMessage('Name is required');
        } else if( !formdata.email ){
          setError(true);
          setMessage('Email is required');
        } else if( !formdata.subject ){
          setError(true);
          setMessage('Subject is required');
        } else if( !formdata.message ){
          setError(true);
          setMessage('Message is required');
        } else{
          setError(false);
          setMessage('You message has been sent!!!');
        }*/
      }
      
      handleChange = (event) => {
        /*setFormdata({
          ...formdata,
          [event.currentTarget.name] : event.currentTarget.value
        })*/
      }
      
      numberFormatter = (number) =>{
        const phnNumber = number;
        return phnNumber;
      }
    
      handleAlerts = () => {
        /*if(error && message){
          return (
            <div className="alert alert-danger mt-4">
              {message}
            </div>
          )
        } else if(!error && message){
          return (
            <div className="alert alert-success mt-4">
              {message}
            </div>
          )
        } else{
          return null;
        }*/
      }

      physicalAddress = "blah";
      emailAddresses = ["blah1"];
      phoneNumbers = ["extrablah"];

    render() {
        return (
            <Aux >
                <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <SectionTitle title="Contact Me"/>
          <div className="row">
            <div className="col-lg-12">
              <div className="mi-contact-formwrapper">
                <h4>Get In Touch</h4>
                <form action="#" className="mi-form mi-contact-form" onSubmit={this.submitHandler}>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-name">Enter your name*</label>
                    <input onChange={this.handleChange} type="text" name="name" id="contact-form-name" value="testInput"/>
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-email">Enter your email*</label>
                    <input onChange={this.handleChange} type="text" name="email" id="contact-form-email" value="testInput"/>
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-subject">Enter your subject*</label>
                    <input onChange={this.handleChange} type="text" name="subject" id="contact-form-subject" value="testInput"/>
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-message">Enter your Message*</label>
                    <textarea onChange={this.handleChange} name="message" id="contact-form-message" cols="30" rows="6" value="testInput"></textarea>
                  </div>
                  <div className="mi-form-field">
                    <button className="mi-button" type="submit">Send Mail</button>
                  </div>
                </form>
                {this.handleAlerts()}
              </div>
            </div>
          </div>
        </div>
      </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact); // TODO: Re-add withErrorHandler