/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Particles from 'react-particles-js';

import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/Input';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';
/*import classes from './Memories.module.css';
import { Container, Row } from 'react-bootstrap';*/

class AddMemory extends Component {
    state = {
      controls: {
          description: {
              elementType: 'textarea',
              elementConfig: {
                  placeholder: 'What happened?'
              },
              value: '',
              validation: {
                  required: true,
              },
              valid: false,
              touched: false
          },
          file: {
              elementType: 'input',
              elementConfig: {
                  type: 'file',
              },
              value: '',
              validation: {
                  required: false,
              },
              valid: false,
              touched: false
          }
      }
    }

    componentDidMount () {

    }

    paramConfig = {
        particles: {
          number: {
            value: 250,
            density: {
              enable: false
            }
          },
          color: {
            value: "#0fffff"
          },
          opacity: {
            value: 0.3,
            random: true,
            anim: {
              enable: true,
              speed: 0.1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 5,
            random: true,
            anim: {
              speed: 4,
              size_min: 0.3
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            random: true,
            speed: 1,
            direction: "top",
            out_mode: "out"
          }
        }
      };

      checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid;
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid;
        }

        return isValid;
    }

    inputChangedHandler = ( event, controlName ) => {
      const updatedControls = {
          ...this.state.controls,
          [controlName]: {
              ...this.state.controls[controlName],
              value: event.target.value,
              valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
              touched: true,
              fileBlob: event.target.files
          }
      };
      this.setState( { controls: updatedControls } );
    }

    submitHandler = ( event ) => {
      event.preventDefault();
      this.props.onExportMemory(this.state.controls.description.value, this.state.controls.file.fileBlob);
      this.props.history.push('/memories');
    }

    render() {
      const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => {
          if (formElement.id == "file") {
            return (
              <div className="row">
                <div className="col-sm-4"/> 
                <div className="col-sm-4"> 
                  <Input
                      key={formElement.id}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      invalid={!formElement.config.valid}
                      shouldValidate={formElement.config.validation}
                      touched={formElement.config.touched}
                      changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
                </div>
              </div>
            ) 
          } else {
            return (
              <Input
                  key={formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
            ) 
          }
        });

        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

      return (
        <Aux>
          <div className="mi-home-area mi-padding-section">
            <Particles className="mi-home-particle" params={this.paramConfig} />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-12">
                  <div className="mi-home-content">
                    <h1>
                      Add a new <span className="color-theme">Memory</span>
                    </h1>
                    {errorMessage}
                    <form onSubmit={this.submitHandler}>
                        {form}
                        <Button btnType="button--pan">SUBMIT</Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Aux>
      );
    }
}

const mapStateToProps = state => {
    return {
      loading: state.auth.loading,
      error: state.auth.error,
      isAuthenticated: state.auth.token !== null,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onExportMemory: (description, file) => dispatch(actions.exportMemory(description, file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMemory); // TODO: Re-add withErrorHandler