import React, { Component } from 'react';
import { connect } from 'react-redux';
import Particles from 'react-particles-js';

import Aux from '../../hoc/Auxiliary/Auxiliary';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import SocialIcons from '../../components/UI/SocialIcons/SocialIcons';
/*import classes from './Home.module.css';
import { Container, Row } from 'react-bootstrap';*/

class Home extends Component {

    componentDidMount () {
        //console.log(this.props);
    }

    paramConfig = {
        particles: {
          number: {
            value: 250,
            density: {
              enable: false
            }
          },
          color: {
            value: "#0fffff"
          },
          opacity: {
            value: 0.3,
            random: true,
            anim: {
              enable: true,
              speed: 0.1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 5,
            random: true,
            anim: {
              speed: 4,
              size_min: 0.3
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            random: true,
            speed: 1,
            direction: "top",
            out_mode: "out"
          }
        }
      };

    /*render() {
        return (
            <Aux className={classes.HomeArea}>
                <Aux className={classes.ParticleContainer}>
                    <Particles className={classes.Particles} params={this.paramConfig} />
                </Aux>
                <Container fluid> 
                  <Row className="justify-content-md-center">
                    <div className="col-lg-10 col-12">
                      <Aux className={classes.HomeContent}>
                        <h1>Hi, I&apos;m Chance Stovall</h1>
                        <p>I am a Software Engineer with specialties in Data Science, High Performance Computing, and Full-Stack Development.</p>
                      </Aux>
                    </div>
                  </Row>
                </Container>
            </Aux>
        );
    }*/

    render() {
      return (
        <Aux>
          <div className="mi-home-area mi-padding-section">
            <Particles className="mi-home-particle" params={this.paramConfig} />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-12">
                  <div className="mi-home-content">
                    <h1>
                      Hi, I&apos;m <span className="color-theme">Chance Stovall</span>
                    </h1>
                    <p>I am a Software Engineer that specializes in Data Science, High Performance Computing, and Full-Stack Development.</p>
                    <SocialIcons bordered />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Aux>
      );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home); // TODO: Re-add withErrorHandler