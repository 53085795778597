// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_About__2Pndg {\n    height: calc(100vh - 56px);\n    width: 100%;\n    background-color: #10121b;\n    vertical-align: middle;\n}\n\n.About_AboutArea__3ELln {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    position: relative;\n    z-index: 1;\n}\n\n.About_AboutScrollable__38328 {\n    overflow-y: scroll;\n}", "",{"version":3,"sources":["webpack://src/containers/About/About.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,WAAW;IACX,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".About {\n    height: calc(100vh - 56px);\n    width: 100%;\n    background-color: #10121b;\n    vertical-align: middle;\n}\n\n.AboutArea {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    position: relative;\n    z-index: 1;\n}\n\n.AboutScrollable {\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"About": "About_About__2Pndg",
	"AboutArea": "About_AboutArea__3ELln",
	"AboutScrollable": "About_AboutScrollable__38328"
};
export default ___CSS_LOADER_EXPORT___;
