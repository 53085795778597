// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DrawerToggle_DrawerToggle__m405X {\n    width: 40px;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    justify-content: space-around;\n    align-items: center;\n    padding: 10px 0;\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.DrawerToggle_DrawerToggle__m405X div {\n    width: 90%;\n    height: 3px;\n    background-color: white;\n}\n\n@media (min-width: 500px) {\n    .DrawerToggle_DrawerToggle__m405X {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/SideDrawer/DrawerToggle/DrawerToggle.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".DrawerToggle {\n    width: 40px;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    justify-content: space-around;\n    align-items: center;\n    padding: 10px 0;\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.DrawerToggle div {\n    width: 90%;\n    height: 3px;\n    background-color: white;\n}\n\n@media (min-width: 500px) {\n    .DrawerToggle {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DrawerToggle": "DrawerToggle_DrawerToggle__m405X"
};
export default ___CSS_LOADER_EXPORT___;
