// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.Layout_Content__3H3X8 {\n    margin-top: 56px;\n    background: #10121b;\n    font-family: Nunito, sans-serif;\n    color: #a4acc4;\n    line-height: 1.8rem;\n    font-size: 18x;\n    -webkit-font-smoothing: antialiased;\n    overflow: auto;\n    max-height: calc(100vh - 56px);\n}\n\n.Layout_hidden__27w24 {\n    overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/hoc/Layout/Layout.module.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,+BAA+B;IAC/B,cAAc;IACd,mBAAmB;IACnB,cAAc;IACd,mCAAmC;IACnC,cAAc;IACd,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n\n.Content {\n    margin-top: 56px;\n    background: #10121b;\n    font-family: Nunito, sans-serif;\n    color: #a4acc4;\n    line-height: 1.8rem;\n    font-size: 18x;\n    -webkit-font-smoothing: antialiased;\n    overflow: auto;\n    max-height: calc(100vh - 56px);\n}\n\n.hidden {\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Content": "Layout_Content__3H3X8",
	"hidden": "Layout_hidden__27w24"
};
export default ___CSS_LOADER_EXPORT___;
