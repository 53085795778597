// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Backdrop_Backdrop__3j6VK {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n}", "",{"version":3,"sources":["webpack://src/components/UI/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,OAAO;IACP,MAAM;IACN,oCAAoC;AACxC","sourcesContent":[".Backdrop {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "Backdrop_Backdrop__3j6VK"
};
export default ___CSS_LOADER_EXPORT___;
