// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input_Input__3r5Ke {\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n}\n\n.Input_Label__1qyHr {\n    font-weight: bold;\n    display: block;\n    margin-bottom: 8px;\n}\n\n.Input_InputElement__2m88K {\n    border-radius: 20px;\n    outline: none;\n    border: 1px solid rgb(240, 240, 240);\n    background-color: white;\n    font: inherit;\n    padding: 6px 10px;\n    display: block;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.Input_InputElement__2m88K:focus {\n    outline: none;\n    background-color: #ccc;\n}\n\n.Input_Invalid__16Mis {\n    border: 1px solid red;\n    background-color: #FDA49A;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,oCAAoC;IACpC,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":[".Input {\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n}\n\n.Label {\n    font-weight: bold;\n    display: block;\n    margin-bottom: 8px;\n}\n\n.InputElement {\n    border-radius: 20px;\n    outline: none;\n    border: 1px solid rgb(240, 240, 240);\n    background-color: white;\n    font: inherit;\n    padding: 6px 10px;\n    display: block;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.InputElement:focus {\n    outline: none;\n    background-color: #ccc;\n}\n\n.Invalid {\n    border: 1px solid red;\n    background-color: #FDA49A;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "Input_Input__3r5Ke",
	"Label": "Input_Label__1qyHr",
	"InputElement": "Input_InputElement__2m88K",
	"Invalid": "Input_Invalid__16Mis"
};
export default ___CSS_LOADER_EXPORT___;
