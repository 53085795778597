/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Particles from 'react-particles-js';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/UI/Button/Button';
//import Spinner from '../../../components/UI/Spinner/Spinner';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
/*import classes from './Memories.module.css';
import { Container, Row } from 'react-bootstrap';*/

class Memories extends Component {
    state = {
        id: null,
        description: null,
        filePath: null
    }

    componentDidMount () {
        this.props.onImportMemory();
    }

    submitHandler = ( event ) => {
        event.preventDefault();
        this.props.onImportMemory();
    }

    paramConfig = {
        particles: {
          number: {
            value: 250,
            density: {
              enable: false
            }
          },
          color: {
            value: "#0fffff"
          },
          opacity: {
            value: 0.3,
            random: true,
            anim: {
              enable: true,
              speed: 0.1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 5,
            random: true,
            anim: {
              speed: 4,
              size_min: 0.3
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            random: true,
            speed: 1,
            direction: "top",
            out_mode: "out"
          }
        }
      };

    render() {
        let description = null;
        let filePath = null;

        if (this.props.loading) {
            description = "Loading..."
            filePath = "Loading..."
        } else {
            description = this.props.description;
            filePath = this.props.filePath;
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        return (
            <Aux>
            <div className="mi-home-area mi-padding-section">
                <Particles className="mi-home-particle" params={this.paramConfig} />
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                    <div className="mi-home-content">
                        <h1>
                        Oh lookie! A <span className="color-theme">Memory</span>
                        </h1>
                        {errorMessage}
                        <p>{description}</p>
                        <p><img src={filePath}></img></p>
                        <Button clicked={this.submitHandler} btnType="Success">Find Another Memory</Button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        id: state.memory.id,
        description: state.memory.description,
        filePath: state.memory.filePath,
        loading: state.memory.loading,
        error: state.memory.error
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onImportMemory: () => dispatch(actions.importMemory())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Memories); // TODO: Re-add withErrorHandler