import React, { Component } from 'react';
import { connect } from 'react-redux';
import TrackVisibility from 'react-on-screen';


import Aux from '../../hoc/Auxiliary/Auxiliary';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
//import classes from './Resume.module.css';
import SmallTitle from '../../components/Wrappers/SmallTitle/SmallTitle';
import SectionTitle from '../../components/Wrappers/SectionTitle/SectionTitle';
import ProgressBar from '../../components/UI/ProgressBar/ProgressBar';
import ResumeSection from '../../components/Wrappers/ResumeSection/ResumeSection';

class Resume extends Component {

    skills = [
        {
            title: "C#",
            value: 95
        },
        {
            title: "C++",
            value: 95
        },
        {
            title: "ASP.NET Core",
            value: 90
        },
        {
            title: "Python",
            value: 80
        },
        {
            title: "JavaScript",
            value: 95
        },
        {
            title: "ReactJS",
            value: 90
        },
        {
            title: "Java",
            value: 95
        },
        {
            title: "TensorFlow",
            value: 90
        },
        {
            title: "High Performance Computing",
            value: 90
        },
        {
            title: "NodeJS",
            value: 95
        },
        {
            title: "Kubernetes",
            value: 80
        },
        {
            title: "Docker",
            value: 80
        },
        {
            title: "CI/CD",
            value: 85
        },
        {
            title: "Data Science",
            value: 85
        }
    ];

    workingExperience = [
        {
            id: 1,
            year: "2021 - Present",
            position: "Research Engineer 2",
            company: "Indeed",
            details: [
                {
                    id: 1,
                    value: "Placeholder"
                },
            ]
        },
        {
          id: 2,
          year: "2019 - 2021",
          position: "Software Engineer",
          company: "Dell Technologies",
          details: [
              {
                  id: 1,
                  value: "Designed and developed distributed, cloud-based enterprise eCommerce software for Dell’s complex business domain and technical needs."
              },
              {
                id: 2,
                value: "Led mission-critical projects and created efficient microservices with high CI/CD maturity scores and automated unit and integration testing while improving existing architecture and standards."
              },
              {
                id: 3,
                value: "Created a dashboard to monitor the health of all servers, deployments, and major endpoints and send notifications when needed."
              },
              {
                id: 4,
                value: "Increased Performance of client-facing applications and microservices by more than 85%"
              }
          ]
        },
        {
          id: 3,
          year: "2018 - 2019",
          position: "Software Developer",
          company: "Texas State University",
          details: [
              {
                  id: 1,
                  value: "Developed, Designed, and implemented educational software application components in C# for collecting data from various community participants"
              },
              {
                id: 2,
                value: "Produced efficient and effective code based on provided specifications in addition to troubleshooting, debugging, and upgrading software to meet industry standards."
              }
            ]
        },
        {
          id: 4,
          year: "2017",
          position: "Disaster Recovery Specialist",
          company: "Rentsys Recovery Services",
          details:[
            {
                id: 1,
                value: "Performed routine checkups and created redundant systems for different types of machines."
            },
            {
                id: 2,
                value: "Created documentation on newly received hardware and ran functional tests to simulate different conditions/environments."
            },
            {
                id: 3,
                value: "Imaged and configured laptops, desktops, and servers."
            },
            {
                id: 4,
                value: "Set up RAID controllers and extensions, and assisted in training incoming interns."
            }
          ]
        }
    ];

    educationExperience = [
        /*{
          id: 1,
          year: "2021 - Present",
          graduation: "M.S. Computer Science",
          university: "University of Texas at Austin",
          details: [
            {
                id: 1,
                value: "Academic focus in Data Science"
            }
          ]
        },*/
        {
          id: 2,
          year: "2017 - 2019",
          graduation: "B.S. Computer Science",
          university: "Texas State University (San Marcos)",
          details: [
            {
                id: 1,
                value: "Minor in Mathematics"
            },
            {
                id: 2,
                value: "3.80 GPA / Summa Cum Laude"
            }
          ]
        }
    ];

    awards = [
        {
            id: 1,
            year: 2019,
            award: "1st Place at Texas-Mexico International Collegiate Programming Competition",
            details: [
                {
                    id: 1,
                    value: "Participated as part of the top team of three students at the Texas-Mexico International Collegiate Programming Competition. The International Collegiate Programming Contest (ICPC) is an annual competitive programming competition held globally by and for the world's universities."
                }
              ]
        },
        {
            id: 2,
            year: 2019,
            award: "Computer Science Academic Excellence Award",
            details: [
                {
                    id: 1,
                    value: "Award given for academic excellence in Computer Science. Awards were given to computer science students who have at least a 3.8 Texas State GPA, 3.6 or better major GPA for computer science, have completed a minimum of 30 hours of course work at Texas State University and a minimum of 9 hours of coursework in computer science courses. 50 out of the 1400 Texas State computer science students were given this award."
                }
              ]
        }
    ];

    componentDidMount () {
        //console.log(this.props);
    }

    render() {
        return (
            <Aux>
                <div className="mi-skills-area mi-section mi-padding-top">
                    <div className="container">
                    <SectionTitle title="My Skills" />
                    <div className="mi-skills">
                        <div className="row mt-30-reverse">
                        {this.skills.map(skill => (
                            <TrackVisibility partialVisibility className="col-lg-6 mt-30" key={skill.title}>
                                {({ isVisible }) => <ProgressBar isVisible={isVisible} title={skill.title} percentage={skill.value} />}
                            </TrackVisibility>
                        ))}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mi-resume-area mi-section mi-padding-top">
                    <div className="container">
                    <SectionTitle title="Resume" />
                        <SmallTitle title="Working Experience" icon="briefcase" />
                        <div className="mi-resume-wrapper">
                            {this.workingExperience.map(workingExp => (
                                <ResumeSection key={workingExp.id} resumeData={workingExp} />
                            ))}
                        </div>
                        <div className="mt-30"></div>
                        <SmallTitle title="Educational Qualifications" icon="book" />
                        <div className="mi-resume-wrapper">
                            {this.educationExperience.map(educatonExp => (
                                <ResumeSection key={educatonExp.id} resumeData={educatonExp}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
                    <div className="container">
                    <SectionTitle title="Awards" />
                        <div className="mi-resume-wrapper">
                            {this.awards.map(workingExp => (
                                <ResumeSection key={workingExp.id} resumeData={workingExp} />
                            ))}
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume); // TODO: Re-add withErrorHandler