export const EXPORT_MEMORY_START = 'EXPORT_MEMORY_START'
export const EXPORT_MEMORY_SUCCESS = 'EXPORT_MEMORY_SUCCESS';
export const EXPORT_MEMORY_FAIL = 'EXPORT_MEMORY_FAIL';
export const IMPORT_MEMORY_START = 'IMPORT_MEMORY_START'
export const IMPORT_MEMORY_SUCCESS = 'IMPORT_MEMORY_SUCCESS';
export const IMPORT_MEMORY_FAIL = 'IMPORT_MEMORY_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';