import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProjectItems from '../../components/ProjectItems/ProjectItems';
import SectionTitle from '../../components/Wrappers/SectionTitle/SectionTitle';

import Aux from '../../hoc/Auxiliary/Auxiliary';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
//import classes from './Projects.module.css';

class Projects extends Component {

    projectItems = [];

    componentDidMount () {
        //console.log(this.props);
    }

    render() {
        return (
            <Aux>
                <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                    <div className="container">
                        <SectionTitle title="Projects" />
                        <ProjectItems projectItems={this.projectItems} />
                    </div>
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects); // TODO: Re-add withErrorHandler