import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionTitle from '../../components/Wrappers/SectionTitle/SectionTitle';
import chanceAbout from '../../assets/images/TempAbout.JPG';

import Aux from '../../hoc/Auxiliary/Auxiliary';
//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import classes from './About.module.css';

class About extends Component {

    componentDidMount () {
        console.log(this.props);
    }

    render() {
        let information = {
            name: "Chance Stovall",
            age: 25,
            phone: "281-948-3148",
            nationality: "American",
            language: "English, Spanish, French",
            freelanceStatus: "Available",
            cvfile: "resume"
        };

        let aboutImg = <img src={chanceAbout} alt="Chance Stovall" />
        return (
            <Aux className={classes.AboutScrollable}>
                <div className="mi-about-area mi-section mi-padding-top">
                    <div className="container">
                        <SectionTitle title="About Me" />
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mi-about-image">
                                    {/*<ProgressiveImage
                                    src={information.aboutImage}
                                    placeholder="/images/about-image-placeholder.png"
                                    >
                                    {src => <img src={src} alt="aboutimage" onClick={() => handleToggler(!toggler)} />}
                                    </ProgressiveImage>
                                    <span className="mi-about-image-icon">
                                    <Icon.ZoomIn />
                                    </span>
                                    <FsLightbox
                                    toggler={toggler}
                                    sources={[information.aboutImageLg]}
                                    />*/}
                                    {aboutImg}
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="mi-about-content">
                                <h3>
                                Hi, I&apos;m <span className="color-theme">{information.name}</span>
                                </h3>
                                <p>
                                I am a Software Engineer that specializes in Data Science, High Performance Computing, and Full-Stack Development.
                                </p>
                                <ul>
                                {!information.name ? null : (
                                    <li>
                                    <b>Full Name</b> {information.name}
                                    </li>
                                )}
                                {!information.age ? null : (
                                    <li>
                                    <b>Age</b> {information.age}
                                    </li>
                                )}
                                {!information.phone ? null : (
                                    <li>
                                    <b>Phone</b> {information.phone}
                                    </li>
                                )}
                                {!information.language ? null : (
                                    <li>
                                    <b>Languages</b> {information.language}
                                    </li>
                                )}
                                {!information.email ? null : (
                                    <li>
                                    <b>Email</b> {information.email}
                                    </li>
                                )}
                                {!information.address ? null : (
                                    <li>
                                    <b>Address</b> {information.address}
                                    </li>
                                )}
                                {!information.freelanceStatus ? null : (
                                    <li>
                                    <b>Freelance</b> {information.freelanceStatus}
                                    </li>
                                )}
                                </ul>
                                <a href={information.cvfile} className="mi-button">
                                Download CV
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </Aux>
        );
    }
}

/*
<div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
                        <div className="container">
                            <SectionTitle title="Reviews" />
                            <div className="row justify-content-center">
                                <div className="col-12">
                                {<Slider className="mi-testimonial-slider" {...sliderSettings}>
                                    {reviews.map((review) => (
                                    <Testimonial key={review.id} content={review} />
                                    ))}
                                </Slider>}
                                </div>
                            </div>
                        </div>
                    </div>*/

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About); // TODO: Re-add withErrorHandler