// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_Navbar__2y1ns {\n    height: 56px;\n    width: 100%;\n    position: fixed;\n    top: 0px;\n    background-color: #2e344e;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n    box-sizing: border-box;\n    z-index: 90;\n    border-bottom: 1px solid #a4acc4;\n    overflow: hidden;\n}\n\n.Navbar_Navbar__2y1ns nav {\n    height: 100%;\n}\n\n.Navbar_Logo__19luv {\n    height: 80%;\n}\n\n@media (max-width: 499px) {\n    .Navbar_DesktopOnly__iPguU {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/Navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,QAAQ;IACR,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,WAAW;IACX,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".Navbar {\n    height: 56px;\n    width: 100%;\n    position: fixed;\n    top: 0px;\n    background-color: #2e344e;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n    box-sizing: border-box;\n    z-index: 90;\n    border-bottom: 1px solid #a4acc4;\n    overflow: hidden;\n}\n\n.Navbar nav {\n    height: 100%;\n}\n\n.Logo {\n    height: 80%;\n}\n\n@media (max-width: 499px) {\n    .DesktopOnly {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navbar": "Navbar_Navbar__2y1ns",
	"Logo": "Navbar_Logo__19luv",
	"DesktopOnly": "Navbar_DesktopOnly__iPguU"
};
export default ___CSS_LOADER_EXPORT___;
