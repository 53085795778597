import axios from 'axios';

import * as actionTypes from './actionTypes';

export const exportMemoryStart = () => {
    return {
        type: actionTypes.EXPORT_MEMORY_START
    };
};

export const exportMemorySuccess = (id, description, filePath) => {
    return {
        type: actionTypes.EXPORT_MEMORY_SUCCESS,
        id: id,
        description: description,
        filePath: filePath
    };
};

export const exportMemoryFail = (error) => {
    return {
        type: actionTypes.EXPORT_MEMORY_FAIL,
        error: error
    };
};

export const exportMemory = (description, file) => {
    return dispatch => {
        dispatch(exportMemoryStart());
        const bodyFormData = new FormData();
        bodyFormData.append('description', description);
        if (file && file[0]) {
            bodyFormData.append('file', file[0]);
        }
        // TODO: Move this to env var
        let url = 'https://api.chancestovall.com/memories/';
        axios({
            method: "post",
            url: url,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(response => {
                dispatch(exportMemorySuccess(response.data._id, response.data.description, response.data.filePath));
            })
            .catch(err => {
                dispatch(exportMemoryFail(err.response.data.error));
            });
    };
};

export const importMemoryStart = () => {
    return {
        type: actionTypes.IMPORT_MEMORY_START
    };
};

export const importMemorySuccess = (id, description, filePath) => {
    return {
        type: actionTypes.IMPORT_MEMORY_SUCCESS,
        id: id,
        description: description,
        filePath: filePath
    };
};

export const importMemoryFail = (error) => {
    return {
        type: actionTypes.IMPORT_MEMORY_FAIL,
        error: error
    };
};

export const importMemory = () => {
    return dispatch => {
        dispatch(importMemoryStart());
        let url = 'https://api.chancestovall.com/memories/';
        axios.get(url)
            .then(response => {
                dispatch(importMemorySuccess(response.data._id, response.data.description, response.data.filePath));
            })
            .catch(err => {
                console.log(err);
                dispatch(importMemoryFail(err));
            });
    };
};