/* eslint-disable react/prop-types */
import React from "react";
//import axios from 'axios';
import LineIcon from 'react-lineicons';

function SocialIcons(props){

  /*useEffect(() => {
    axios.get('/api/information')
      .then(response =>{
        setSocialLinks(response.data.socialLinks);
      })
  }, [])*/

  /*{!socialLinks.facebook ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.facebook}>
      <LineIcon name="facebook"/>
    </a>
  </li>}
  {!socialLinks.twitter ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.twitter}>
      <LineIcon name="twitter"/>
    </a>
  </li>}
  {!socialLinks.pinterest ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.pinterest}>
      <LineIcon name="pinterest"/>
    </a>
  </li>}
  {!socialLinks.behance ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.behance}>
      <LineIcon name="behance"/>
    </a>
  </li>}
  {!socialLinks.linkedin ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.linkedin}>
      <LineIcon name="linkedin"/>
    </a>
  </li>}
  {!socialLinks.dribbble ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.dribbble}>
      <LineIcon name="dribbble"/>
    </a>
  </li>}
  {!socialLinks.github ? null : <li>
    <a rel="noopener noreferrer" target="_blank" href={socialLinks.github}>
      <LineIcon name="github"/>
    </a>
  </li>}*/


  return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
        <li>
            <a rel="noopener noreferrer" target="_blank" href="https://github.com/ChanceStovall">
                <LineIcon name="github"/>
            </a>
        </li>
        <li>
            <a rel="noopener noreferrer" target="_blank" href="https://linkedin.com/in/chancestovall">
            <LineIcon name="linkedin"/>
            </a>
        </li>
    </ul>
  );
}

export default SocialIcons;
