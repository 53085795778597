// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminPanel_AdminPanel__2RxwE {\n    height: calc(100vh - 56px);\n    width: 100%;\n    background-color: #10121b;\n}", "",{"version":3,"sources":["webpack://src/containers/AdminPanel/AdminPanel.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":[".AdminPanel {\n    height: calc(100vh - 56px);\n    width: 100%;\n    background-color: #10121b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdminPanel": "AdminPanel_AdminPanel__2RxwE"
};
export default ___CSS_LOADER_EXPORT___;
