/* eslint-disable react/prop-types */
import React from "react";

function ResumeSection(props) {
  const {year, position, graduation, university, company, details, award} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation || award }</h5>
        <h6 className="mi-resume-company">{company || university}</h6>
        <ul>
          {details.map(detail => (
              <li key={detail.id}>{detail.value}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ResumeSection;
