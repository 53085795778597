/* eslint-disable react/prop-types */
import React from "react";
import ProjectItem from './ProjectItem/ProjectItem';

function ProjectItems({ projectItems }) {
  return (
    <div className="row mt-30-reverse">
      {projectItems.map(projectItem => (
        <div className="col-lg-4 col-md-6 col-12 mt-30" key={projectItem.id} >
          <ProjectItem content={projectItem} />
        </div>
      ))}
    </div>
  );
}

export default ProjectItems;
