// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideDrawer_SideDrawer__3kXLK {\n    position: fixed;\n    width: 280px;\n    max-width: 70%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: 200;\n    background-color: #2e344e;\n    display: flex;\n    padding: 32px 16px;\n    box-sizing: border-box;\n    transition: transform 0.3s ease-out;\n    border-right: 1px solid #a4acc4;\n}\n\n@media (min-width: 500px) {\n    .SideDrawer_SideDrawer__3kXLK {\n        display: none;\n    }\n}\n\n.SideDrawer_Open__1tCv1 {\n    transform: translateX(0);\n}\n\n.SideDrawer_Close__9j7x- {\n    transform: translateX(-100%);\n}\n\n.SideDrawer_Logo__3voUv {\n    height: 11%;\n    margin-bottom: 32px;\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/SideDrawer/SideDrawer.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,OAAO;IACP,MAAM;IACN,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".SideDrawer {\n    position: fixed;\n    width: 280px;\n    max-width: 70%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: 200;\n    background-color: #2e344e;\n    display: flex;\n    padding: 32px 16px;\n    box-sizing: border-box;\n    transition: transform 0.3s ease-out;\n    border-right: 1px solid #a4acc4;\n}\n\n@media (min-width: 500px) {\n    .SideDrawer {\n        display: none;\n    }\n}\n\n.Open {\n    transform: translateX(0);\n}\n\n.Close {\n    transform: translateX(-100%);\n}\n\n.Logo {\n    height: 11%;\n    margin-bottom: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SideDrawer": "SideDrawer_SideDrawer__3kXLK",
	"Open": "SideDrawer_Open__1tCv1",
	"Close": "SideDrawer_Close__9j7x-",
	"Logo": "SideDrawer_Logo__3voUv"
};
export default ___CSS_LOADER_EXPORT___;
