/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import Layout from './hoc/Layout/Layout';
import Landing from './containers/Home/Home';
import Projects from './containers/Projects/Projects';
import About from './containers/About/About';
import Contact from './containers/Contact/Contact';
import Resume from './containers/Resume/Resume';
import AdminPanel from './containers/AdminPanel/AdminPanel';
import Memories from './containers/Memories/Memories';
import AddMemory from './containers/Memories/AddMemory/AddMemory';
//import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';

class App extends Component {
    componentDidMount () {
        this.props.onTryAutoSignin();
    }

    render() {
        // TODO: Re-add all other routes
        /*<Route path="/auth" component={Auth}/>
                <Route path="/projects" component={Projects}/>
                <Route path="/about" component={About}/>
                <Route path="/contact" component={Contact}/>*/

        let routes = (
            <Switch>
                <Route path="/resume" component={Resume}/>
                <Route path="/memories" component={Memories}/>
                <Route path="/addmemory" component={AddMemory}/>
                <Route path="/about" component={About}/>
                <Route path="/" exact component={Landing}/>
                <Redirect to="/"/>
            </Switch>
        );

        if (this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/adminpanel" component={AdminPanel}/>
                    <Route path="/projects" component={Projects}/>
                    <Route path="/about" component={About}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/resume" component={Resume}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/memories" component={Memories}/>
                    <Route path="/addmemory" component={AddMemory}/>
                    <Route path="/" exact component={Landing}/>
                    <Redirect to="/"/>
                </Switch>
            );
        }

        return (
            <div>
                <Layout>
                    {routes}
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignin: () => dispatch(actions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

